exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-bn-index-js": () => import("./../../../src/pages/bn/index.js" /* webpackChunkName: "component---src-pages-bn-index-js" */),
  "component---src-pages-edit-js": () => import("./../../../src/pages/edit.js" /* webpackChunkName: "component---src-pages-edit-js" */),
  "component---src-pages-hi-index-js": () => import("./../../../src/pages/hi/index.js" /* webpackChunkName: "component---src-pages-hi-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kn-index-js": () => import("./../../../src/pages/kn/index.js" /* webpackChunkName: "component---src-pages-kn-index-js" */),
  "component---src-pages-medium-blog-featured-image-generator-js": () => import("./../../../src/pages/medium-blog-featured-image-generator.js" /* webpackChunkName: "component---src-pages-medium-blog-featured-image-generator-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-release-notes-js": () => import("./../../../src/pages/release-notes.js" /* webpackChunkName: "component---src-pages-release-notes-js" */),
  "component---src-pages-share-js": () => import("./../../../src/pages/share.js" /* webpackChunkName: "component---src-pages-share-js" */),
  "component---src-pages-ta-index-js": () => import("./../../../src/pages/ta/index.js" /* webpackChunkName: "component---src-pages-ta-index-js" */),
  "component---src-pages-te-index-js": () => import("./../../../src/pages/te/index.js" /* webpackChunkName: "component---src-pages-te-index-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */),
  "component---src-pages-whatsapp-status-creator-online-free-js": () => import("./../../../src/pages/whatsapp-status-creator-online-free.js" /* webpackChunkName: "component---src-pages-whatsapp-status-creator-online-free-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

